.fil_unes {
  article {
    margin-bottom: $margin-petit;
    h5 {
      margin: 0;
      font-size: .9em;
      font-weight: 400;
      line-height: 1.1rem;
    }
  }
  article img {
    float: left;
    margin-right: $margin-horizontale;
  }
  // a:last-child article {
  //   margin-bottom: 10px;
  // }
}

.fil_manchette {
  article {
    padding-bottom: $margin-petit;
    margin-bottom: $margin-petit;
    h4 {
      padding-left: $margin-horizontale;
      margin: $margin-petit 0;
      font-size: 1.2em;
      font-weight: 400;
    }
  }
}
