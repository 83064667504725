.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1, .card-panel, .card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}

.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

.card-panel {
  transition: box-shadow .25s;
  padding: 24px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
}

.card {
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  transition: box-shadow .25s;
  border-radius: 2px;
  display: none;
}
.card .card-title {
  font-size: 20px;
  font-weight: bold;
}

.card-title time {
  padding-left: 1rem;
  font-size: 0.8rem;
}
.card-image .card-title time {
  color: #fff;
}

.card .card-title.activator {
  cursor: pointer;
}
.card.small, .card.medium, .card.large {
  position: relative;
}
.card.small .card-image, .card.medium .card-image, .card.large .card-image {
  max-height: 60%;
  overflow: hidden;
}
.card.small .card-image + .card-content, .card.medium .card-image + .card-content, .card.large .card-image + .card-content {
  max-height: 40%;
}
.card.small .card-content, .card.medium .card-content, .card.large .card-content {
  max-height: 100%;
  overflow: hidden;
}
.card.small .card-action, .card.medium .card-action, .card.large .card-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.card.small {
  height: 300px;
}
.card.medium {
  height: 400px;
}
.card.large {
  height: 500px;
}
.card.horizontal {
  display: flex;
}
.card.horizontal.small .card-image, .card.horizontal.medium .card-image, .card.horizontal.large .card-image {
  height: 100%;
  max-height: none;
  overflow: visible;
}
.card.horizontal.small .card-image img, .card.horizontal.medium .card-image img, .card.horizontal.large .card-image img {
  height: 100%;
}
.card.horizontal .card-image {
  max-width: 50%;
}
.card.horizontal .card-image img {
  border-radius: 2px 0 0 2px;
  max-width: 100%;
  width: auto;
}
.card.horizontal .card-stacked {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.card.horizontal .card-stacked .card-content {
  flex-grow: 1;
}
.card.sticky-action .card-action {
  z-index: 2;
}
.card.sticky-action .card-reveal {
  z-index: 1;
  padding-bottom: 64px;
}
.card .card-image {
  position: relative;
}
.card .card-image img {
  display: block;
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.card .card-image .card-title {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  padding: 24px;
}
.card .card-content {
  padding: 24px;
  border-radius: 0 0 2px 2px;
  background: #f5f5f5;
}
.card .card-content p {
  margin: 0;
  color: inherit;
  font-size: 1rem;
  white-space: pre-line;
}
.card-content a, .card-content a:hover {
  color: #a30006;
}
.card .card-content .card-title {
  display: block;
  line-height: 32px;
  margin-bottom: 8px;
}
.card .card-content .card-title i {
  line-height: 32px;
}
.card .card-action {
  position: relative;
  background-color: inherit;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  padding: 16px 24px;
}
.card .card-action:last-child {
  border-radius: 0 0 2px 2px;
}
.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating) {
  color: red;
  margin-right: 24px;
  transition: color .3s ease;
  text-transform: uppercase;
}
.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating):hover {
  color: #ff6666;
}
.card .card-reveal {
  padding: 24px;
  position: absolute;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  left: 0;
  top: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}
.card .card-reveal .card-title {
  cursor: pointer;
  display: block;
}

/* AJOUT MANUEL */

section.ligne {
  border-left: 3px solid #AAA;
  padding-left: 34px;
  padding-top: 15px;
  margin-left: 20px;
}

img.status {
  width: 40px !important;
  position: absolute !important;
  margin-left: -79px;
  margin-top: -50px;
}

img.status-image {
  width: 40px !important;
  position: absolute !important;
  margin-left: -54px;
  margin-top: -67px;
  left: inherit !important;
  right: inherit !important;
  top: inherit !important;
  bottom: inherit !important;
}

.new {
  display: block !important;
}
