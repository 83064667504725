// Menu Fixe
//
// Style de l'entête
//
//
//
// Markup: <div class='menu_fixe'><ul><li><a href="">Menu 1</a></li></ul></div>
//
// Styleguide modules.menufixe

.barre-outil {
  padding: 0;
  li {
    font-size: 13px;
    a {
      padding: 0px 10px;
    }
  }
  .inscription {
  }
}
