/* Publiez Pied */
.publiez-pied {
  padding-top:$line-height;
  padding-bottom:$line-height;
  text-align: center;
  h1 {
    font-style: italic;
  }
  h2 {
    text-transform: uppercase;
  }
}
