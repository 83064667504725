/* legende image */

.spip_doc_descriptif, .spip_doc_titre {
  color: #696969;
}
.spip_doc_descriptif {
  padding-top: 5px;
}

.spip_documents {
  text-align: center;
}

.spip_doc_titre {
  margin-top: 5px;
}
