// Suggestions d'article en pied de page
//
// Les styles des suggestions de pied
//
// h3   - Titre des articles
// h4   - Titres des liens
//
// Markup: <div class='suggest_pied'><h3 class="{{modifier_class}}"/></div>
//
// Styleguide components.suggest_pied


.suggest_pied {
  figure {
    margin-bottom:0;
  }
  article {
    margin-bottom:$line-height;
  }
  .h5 {
    margin-top:$margin-petit;
  }

}

.suggest h4 {
  font-size: 1.1em;
}
.fil_manchette article .image-small {
  height: 120px;
  overflow: hidden;
}
