.moment {
  font-size: .9em;
  a {
    padding: 0 4px;
  }
  a:hover {
    text-decoration: underline;
  }
  span {
    padding-left: 9px;
  }
  .legend {
    display: inline-block;
    margin-bottom: $line-height*0.5;
    text-transform: uppercase;
  }
  .mots-clefs-actu {
    display: inline-block;
    padding-left: 5px;
    margin: 5px 0;
    li {
      display: inline-block;
    }
  }
}
