/* ===========================
   Font-face
   =========================== */

/* Fjalla One */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;

  src: url('fonts/fjallaone-regular-webfont.eot');
  src: url('fonts/fjallaone-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/fjallaone-regular-webfont.woff') format('woff'),
  url('fonts/fjallaone-regular-webfont.woff2') format('woff2'),
  url('fonts/fjallaone-regular-webfont.ttf') format('truetype'),
  url('fonts/fjallaone-regular-webfont.svg#fjalla_oneregular') format('svg');
}

/* PT SANS */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;

  src: url('fonts/pt_sans-web-regular-webfont.eot');
  src: url('fonts/pt_sans-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/pt_sans-web-regular-webfont.woff') format('woff'),
  url('fonts/pt_sans-web-regular-webfont.woff2') format('woff2'),
  url('fonts/pt_sans-web-regular-webfont.ttf') format('truetype'),
  url('fonts/pt_sans-web-regular-webfont.svg#pt_sansregular') format('svg');
}

/* PT SERIF */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;

  src: url('fonts/pt_serif-web-regular-webfont.eot');
  src: url('fonts/pt_serif-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/pt_serif-web-regular-webfont.woff') format('woff'),
  url('fonts/pt_serif-web-regular-webfont.woff2') format('woff2'),
  url('fonts/pt_serif-web-regular-webfont.ttf') format('truetype'),
  url('fonts/pt_serif-web-regular-webfont.svg#pt_sansregular') format('svg');
}

@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;

  src: url('fonts/pt_serif-web-italic-webfont.eot');
  src: url('fonts/pt_serif-web-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/pt_serif-web-italic-webfont.woff') format('woff'),
  url('fonts/pt_serif-web-italic-webfont.woff2') format('woff2'),
  url('fonts/pt_serif-web-italic-webfont.ttf') format('truetype'),
  url('fonts/pt_serif-web-italic-webfont.svg#pt_sansregular') format('svg');
}

@font-face {
  font-family: 'PT Serif';
  font-style: bold;
  font-weight: 700;

  src: url('fonts/pt_serif-web-bold-webfont.eot');
  src: url('fonts/pt_serif-web-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/pt_serif-web-bold-webfont.woff') format('woff'),
  url('fonts/pt_serif-web-bold-webfont.woff2') format('woff2'),
  url('fonts/pt_serif-web-bold-webfont.ttf') format('truetype'),
  url('fonts/pt_serif-web-bold-webfont.svg#pt_sansregular') format('svg');
}

@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;

  src: url('fonts/pt_serif-web-bolditalic-webfont.eot');
  src: url('fonts/pt_serif-web-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/pt_serif-web-bolditalic-webfont.woff') format('woff'),
  url('fonts/pt_serif-web-bolditalic-webfont.woff2') format('woff2'),
  url('fonts/pt_serif-web-bolditalic-webfont.ttf') format('truetype'),
  url('fonts/pt_serif-web-bolditalic-webfont.svg#pt_sansregular') format('svg');
}


@font-face {
  font-family: 'Chunkfive';
  font-style: normal;
  font-weight: normal;

  src: url('fonts/chunkfive_ex-webfont.eot');
  src: url('fonts/chunkfive_ex-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/chunkfive_ex-webfont.woff') format('woff'),
  url('fonts/chunkfive_ex-webfont.woff2') format('woff2'),
  url('fonts/chunkfive_ex-webfont.ttf') format('truetype'),
  url('fonts/chunkfive_ex-webfont.svg#chunkfive_exregular') format('svg');
}

@font-face {
  font-family: 'Oswald';
  font-style: bold;
  font-weight: 700;

  src: url('fonts/oswald-bold-webfont.eot');
  src: url('fonts/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/oswald-bold-webfont.woff') format('woff'),
  url('fonts/oswald-bold-webfont.woff2') format('woff2'),
  url('fonts/oswald-bold-webfont.ttf') format('truetype'),
  url('fonts/oswald-bold-webfont.svg#oswaldbold') format('svg');
}


@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;

  src: url('fonts/oswald-light-webfont.eot');
  src: url('fonts/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/oswald-light-webfont.woff') format('woff'),
  url('fonts/oswald-light-webfont.woff2') format('woff2'),
  url('fonts/oswald-light-webfont.ttf') format('truetype'),
  url('fonts/oswald-light-webfont.svg#oswaldlight') format('svg');
}


@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;

  src: url('fonts/oswald-regular-webfont.eot');
  src: url('fonts/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/oswald-regular-webfont.woff') format('woff'),
  url('fonts/oswald-regular-webfont.woff2') format('woff2'),
  url('fonts/oswald-regular-webfont.ttf') format('truetype'),
  url('fonts/oswald-regular-webfont.svg#oswaldregular') format('svg');
}



/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/merriweather-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light'), local('Merriweather-Light'),
       url('fonts/merriweather-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-300.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/merriweather-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
       url('fonts/merriweather-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/merriweather-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
       url('fonts/merriweather-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/merriweather-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
       url('fonts/merriweather-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/merriweather-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
       url('fonts/merriweather-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/merriweather-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
       url('fonts/merriweather-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-900 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/merriweather-v19-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Black'), local('Merriweather-Black'),
       url('fonts/merriweather-v19-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-900.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-900.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-900italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/merriweather-v19-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'),
       url('fonts/merriweather-v19-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/merriweather-v19-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/merriweather-v19-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/merriweather-v19-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/merriweather-v19-latin-900italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 5, 2018 */



@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('fonts/lato-blackitalic-webfont.woff2') format('woff2'),
         url('fonts/lato-blackitalic-webfont.woff') format('woff');
}


@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: url('fonts/lato-black-webfont.woff2') format('woff2'),
         url('fonts/lato-black-webfont.woff') format('woff');
}


@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
    src: url('fonts/lato-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/lato-bolditalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
    src: url('fonts/lato-bold-webfont.woff2') format('woff2'),
         url('fonts/lato-bold-webfont.woff') format('woff');

}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/lato-italic-webfont.woff2') format('woff2'),
         url('fonts/lato-italic-webfont.woff') format('woff');
}


@font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: url('fonts/lato-regular-webfont.woff2') format('woff2'),
         url('fonts/lato-regular-webfont.woff') format('woff');

}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/lato-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/lato-lightitalic-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Lato';
    font-weight: 300;
    src: url('fonts/lato-light-webfont.woff2') format('woff2'),
         url('fonts/lato-light-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: url('fonts/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('fonts/lato-hairlineitalic-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Lato';
    font-weight: 100;
    src: url('fonts/lato-hairline-webfont.woff2') format('woff2'),
         url('fonts/lato-hairline-webfont.woff') format('woff');
}
