// Header
//
// Style de l'entête
//
// .icon-beer   - Pour payer un coup aux copain-ines | Crowd Rebellyon
//
// Markup: <div>beer</div>
//
// Styleguide layouts.header

.banner {
  .nom_site_spip {
    margin-bottom: 0;
    a {
      text-transform: uppercase;
      vertical-align: top;
    }
  }

  .col-2 {
    margin-top: 2rem;
  }
  .vcenter {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}


.publiez {
  -webkit-transform: rotate(-10deg);
     -moz-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
       -o-transform: rotate(-10deg);
          transform: rotate(-10deg);
  a {
    padding: 7px 12px;
    font-size: 20px;
    background-color: white;
  }
}

.publiez:hover a {
  color: white;
  background-color: black;
  -webkit-transition: all .3s;
     -moz-transition: all .3s;
       -o-transition: all .3s ;
          transition: all .3s ;
}
