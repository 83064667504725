// Fontsize
$police-barre-nav: 14px;
$police-taille-base: 18px;

// Base Line-height
$line-height: 1.4rem;

// Marge
$margin-default:0.8rem;
$margin-petit:$line-height*0.5;
$margin-large:$line-height;
$margin-extralarge:$line-height*2;
$margin-horizontale:8px;

$width-icon:		15px;

$font-size-base : 0.9rem;
