#header-article {
    margin-top: -100px;
    opacity: .2;
    z-index: 10000;
    background-color:white;
    padding-bottom:0px;
    padding-top:0px;
    h3 {
      margin-bottom: 0;
    }
}

.article {
  h1 {
    margin-top:$margin-petit;
    margin-bottom:$margin-petit;
  }
  .time {
    margin-bottom:$margin-petit;
  }
}

.article-apercu {
  p {
    margin-bottom:0;
  }
}

.spip_documents_right {
    margin-right: -8rem;
}

.spip_documents_left {
  margin-left: -8rem;
}
