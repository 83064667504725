.grille {
  column-rule: 1px solid #e6e6e6;
  article {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid !important;
      break-inside: avoid;
  }
  article:first-child {
      margin-top: 0;
  }
}

.grille.colonne-2 {
  @media (min-width: 576px) {
    column-count:1;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    column-count:2;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    column-count:2;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    column-count:2;
  }
  column-gap: 30px;
}


.grille.colonne-3 {
  @media (min-width: 576px) {
    column-count:1;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    column-count:3;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    column-count:3;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    column-count:3;
  }
  column-gap: 30px;
}


.grille.colonne-4 {
  @media (min-width: 576px) {
    column-count:1;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    column-count:4;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    column-count:4;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    column-count:4;
  }
  column-gap: 30px;
}