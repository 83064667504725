.formulaire_spip {
  fieldset {
    legend {
      display: none;
    }
  }
  .explication {
    display: none;
  }
  label {
    display: block;
  }
  label:first-child {
    margin-top: $line-height;
  }
  p {
    margin-top: 0;
    margin-bottom: $line-height*0.5;
  }
  input.text {
    width: 70%;
    height: 50px;
    padding: 6px 8px;
    margin-bottom: 0;
    font-size: 26px;
    font-weight: normal;
    line-height: 21px;
    background: transparent;
            border-radius: 0;

    -webkit-border-radius: 0;
       -moz-border-radius: 0;
  }
  .choix {
    input, label {
      display: inline-block !important;
    }
  }
  input.text:focus {
    border-color: red;
    outline: 0 none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset, 0 0 8px red;
  }
  input[type="submit"] {
    border: 1px solid black;

    @extend .btn;
  }
}

label {
  text-transform: uppercase;
}

input:-webkit-autofill {
  color: #777 !important;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #333 !important;
}

input {
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border linear .2s, box-shadow linear .2s;
     -moz-transition: border linear .2s, box-shadow linear .2s;
       -o-transition: border linear .2s, box-shadow linear .2s;
          transition: border linear .2s, box-shadow linear .2s;
}
