// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}


blockquote.spip:before {
  display: inline-block;
  float:left;
  font-weight: 600;
  font-size: 3.8rem;
  margin-left: -.3em;
  background-color: white;
}

blockquote.spip p {
  padding-left: 1.9em;
}

blockquote.spip p:last-child {
  margin-bottom: 0em;
}
