/* Les boutons d'admin survolent la page (sauf # FORMULAIRE_ADMIN) */
.spip-admin-float {
  right: 38% !important;
}
.spip-admin-boutons {
  padding: .4em 1em;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  background: rgba(245, 245, 245, .7);
}
