/* ===========================
   Base
   =========================== */
html {
  font-size: $police-taille-base;
}

body {
  line-height: $line-height;
  // background-color: white;
}

a {
  color: black;
}

a:hover {
  text-decoration: none;
}

// h1, .h1 {
//   font-size:$police-taille-h1;
//   line-height:$line-height-h1;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }
// h2, .h2 {
//   font-size:$police-taille-h2;
//   line-height:$line-height-h2;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }
// h3, .h3 {
//   font-size:$police-taille-h3;
//   line-height:$line-height-h3;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }
// h4, .h4 {
//   font-size:$police-taille-h4;
//   line-height:$line-height-h4;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }
// h5, .h5 {
//   font-size:$police-taille-h5;
//   line-height:$line-height-h5;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }
// h6, .h6 {
//   font-size:$police-taille-h6;
//   line-height:$line-height-h6;
//   margin-top:$margin-top;
//   margin-bottom:$margin-bottom;
// }

/*Manchettes*/
// article h2 {
//   padding-top: .2em;
//   font-size: 1.6rem;
//   font-weight: bold;
//   line-height: 1.1em;
// }
// article h3 {
//   font-size: 1.4rem;
//   font-weight: 600;
//   line-height: 1.3em;
// }

/* Titre des sections Fils d'infos, Breves, a lire d'ailleurs */

// section h1 {
//   padding-bottom: 2px;
//   margin-top: 0;
//   margin-bottom: 10px;
//   font-size: 1.2em;
//   font-weight: 400;
//   text-transform: uppercase;
//   border-bottom: solid 1px;
// }
