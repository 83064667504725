.agenda-calendrier {
  h2 {
    padding-bottom: 8px;
    margin: 0;
    margin-bottom: .8em;
  }
  h4 {
    margin: 0;
    font-size: .8rem;
    line-height: 1rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .list {
    margin-top:$margin-petit;
  }
  li {
    line-height:1rem;
    margin-bottom:0.5rem;
  }
  .ville-agenda {
      font-size: .8rem;
  }
  time {
    font-size: .8rem;
  }
  .date-agenda {
    position: relative;
    float: left;
    width: 52px;
    height: 52px;
    padding-top: 0;
    margin-right: 10px;
    line-height: normal;
    .month {
      display: block;
      padding-top: 4px;
      font-size: 8px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
    }
    .day {
      display: block;
      padding-top: 5px;
      font-size: 21px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }

  }
}
