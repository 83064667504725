
#unes {
  article {

  }
  footer {
    img {
      height: 12px;
      vertical-align:middle;
    }
  }
}
