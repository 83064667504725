// Utilitaires
//
// Les styles des unes
//
// .loading   - Annimation pour les chargements en ajax
// .no-gutter   - Suppression des
//
// Markup: <div class="loading"></div>
//
// Styleguide components.utils
.cache-accessibilite {
  position: absolute;
  top: -999px;
  left: -9999px;
}


svg.place-icon {
  width: 20px;
  height: 18px;
}
svg.clock-icon {
  width: 15px;
  height: 15px;
}

blockquote.spip_poesie {
  padding-left: $margin-horizontale;
  margin-top: $margin-petit*0.5;
  margin-bottom: $margin-petit*0.5;
  font-size: $font-size-base;
}


@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(.0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.0);
  }
}

@keyframes scaleout {
  0% {
    -webkit-transform: scale(.0);
            transform: scale(.0);
  } 100% {
    opacity: 0;
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}
