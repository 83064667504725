.manchette {
  .overlay-text {
    position: absolute;
    top: 0;
    right: 0;
  }
  .background-image {
    z-index: -1;
  }
}
