.article-image {
  img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .contenu-texte {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-25%, -50%);
    -ms-transform: translate(-25%, -50%);
  }
  .contenu-titre {
    transition: .2s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-25%, -50%);
    -ms-transform: translate(-25%, -50%);
    .lien-blanc {
      color:white;
    }
  }
  &:hover img {
    opacity: 0.3;
  }
  &:hover .contenu-texte {
    opacity: 1;
  }
  &:hover .contenu-titre {
    opacity: 0;
  }
}
