.barre-nav {
  background-color:white;
  .nav-link {
    padding-top:0;
    padding-bottom:0;
  }
  .navbar-text {
    padding-top:0;
    padding-bottom:0;
  }
  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }
  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }
}
