.article.grandformat {
  margin-top:0;
  header {
  position: relative;
  width: 100%;
  height: 100%;
  }
  header.titre:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0) 0,#ffffff);
  opacity: 1;
  }
  header.titre {
    padding-top: $margin-large*6;
    padding-bottom: $margin-petit;
    margin-bottom: $margin-petit;
  }
}
