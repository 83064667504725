#search-magnifier {
    color: rgb(255, 255, 255);
    border-radius: 0px;
    border: none;
    border-color: initial;
    border-image: initial;
    background-color: inherit;
  }

#search-fullscreen {
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  z-index: 999;
  display:none;
}

.overlay-close {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 20px;
  top: 20px;
  overflow: hidden;
  border: none;
  background: url(img/cross.png) no-repeat center center;
  text-indent: 200%;
  color: transparent;
  outline: none;
  z-index: 100;
}

.formulaire_recherche_full {
  margin: 0 auto;
  margin-top: 120px;
}

.formulaire_recherche_full #recherche {
  display: inline-block;
  border:none;
  height: 60px;
  background-color: #f4f4f2;
  font-size: 2.5em;
  font-style: italic;
  padding: .15em 0.4em;
  text-indent: 4px;
}

.formulaire_recherche_full input[type="submit"] {
  display: inline-block;
  height: 60px;
  background-color: black;
  color:white;
  padding: .15em 0.4em;
  font-size: 2.5em;
  border: none;
}
