.mini-liste-articles {
  ul {
    margin-top:$margin-petit;
    padding-left: 1.5rem;
    li {
      list-style-type: none;
    }
  }
  li:before {
    display: inline-block;
    width: 1.2em;
    margin-left: -1.2em;
    font-family: FontAwesome;
  }
}
.breves.mini-liste-articles {
  li:before {
    content: "\f10d";
  }
}
.lire.mini-liste-articles {
  li:before {
    content: "\f0c1";
  }
}
