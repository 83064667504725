.liste-sans-puce {
  list-style-type: none;
  padding-left:0;
}

// Footer
//
// Style des footer
//
//
//
// Markup: <footer class="pied">
//   <h2>Titre de la section</h2>
//   <ul>
//      <li>Lien #1</li>
//      <li>Lien #2</li>
//  </ul>
// </footer>
//
// Styleguide layouts.footer
footer.pied {
  padding-top:$line-height;
  h2 {
    margin-bottom: $margin-petit;
  }
  ul {
    list-style-type: none;
  }
  img.beer {
    display: inline;
    width: $width-icon;
  }
  #up {
    div {
      width: 40px;
      padding: 0 5px;
    }
    svg {
      width: 30px;
      fill: white;
    }
  }
}
